<script setup></script>

<template>
  <title>404 | 吳元皓</title>
  <h1>404</h1>
  <p lang="zh-tw">
    穿梭者，你好<br />
    你已經踏入到一個所人未知的地方<br />
    你可能需要幫忙一下<br />
    <a href="/">這裡</a>應該可以幫你導覽您回去正確的道路<br />
    如果真的回不去請麻煩寄信件給我：
    <a href="mailto:web+errorpage_yuanhxyz@yuanhau.com">web@yuanhau.com</a>
  </p>
</template>

<style scoped>
body {
  background-color: rgb(6, 36, 75);
  color: white;
  font-family: monospace;
  font-size: 2em;
  text-align: center;
}

h1 {
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

a:hover {
  color: yellow;
}
</style>
